// eslint-disable-next-line @typescript-eslint/triple-slash-reference
/// <reference path="../../typings/synosso.d.ts" />

import axios from 'axios';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { WaitPage } from '../../components/WaitPage';
import settings from '../../settings';
import { Context, defaultUserInfo } from './AuthProvider.context';
import { AuthContext, UserInfo } from './AuthProvider.types';

const accessTokenStorageKey = 'syno_access_key';

const getSavedAccessToken = (): string | undefined => {
  return sessionStorage.getItem(accessTokenStorageKey) ?? undefined;
};

const saveAccessToken = (accessToken: string | undefined): void => {
  if (accessToken) {
    sessionStorage.setItem(accessTokenStorageKey, accessToken);
  } else {
    sessionStorage.removeItem(accessTokenStorageKey);
  }
};

export const AuthProvider: FC = props => {
  const [userInfo, setUserInfo] = useState<UserInfo>(defaultUserInfo);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isConnecting, setIsConnecting] = useState(false);

  async function getUserInfo(accessToken: string | undefined): Promise<UserInfo> {
    if (!accessToken) {
      return defaultUserInfo;
    }

    const userInfo = { ...defaultUserInfo };

    setIsConnecting(true);
    const url = `${settings.synoUrl}/webman/sso/SSOAccessToken.cgi`;
    const params: { [k: string]: string } = {
      action: 'exchange',
      access_token: accessToken,
      app_id: settings.appId
    };
    const queryString = Object.keys(params)
      .map(key => `${key}=${encodeURIComponent(params[key])}`)
      .join('&');
    try {
      const synoUrl = `${url}?${queryString}`;
      const result = await axios.get(`${settings.proxyUrl}${encodeURIComponent(synoUrl)}`);

      if (result.data.success) {
        userInfo.isLoggedIn = true;
        userInfo.userName = result.data.data.user_name;
        setUserInfo(userInfo);
      }
    } catch (error) {
      console.log(error);
    }
    setIsConnecting(false);

    return userInfo;
  }

  const authCallback = useCallback(async (response: SYNOSSO.LoginResponse) => {
    let accessToken = getSavedAccessToken();

    if ('not_login' === response.status) {
      //user not login
      console.log(response.status);
    } else if ('login' === response.status) {
      accessToken = response.access_token;
      saveAccessToken(accessToken);
    } else {
      alert(`error: ${response.status}`);
      //deal with errors;
    }

    await getUserInfo(accessToken);

    setIsLoaded(true);
  }, []);

  const initialize = useCallback(async () => {
    const accessToken = getSavedAccessToken();

    let loaded = isLoaded;

    if (loaded) {
      return;
    }

    if (accessToken) {
      const userInfo = await getUserInfo(accessToken);

      if (userInfo.isLoggedIn) {
        setIsLoaded(true);
        loaded = true;
      } else {
        saveAccessToken(undefined);
      }
    }

    if (!loaded) {
      SYNOSSO.init({
        oauthserver_url: settings.synoUrl,
        app_id: settings.appId,
        redirect_uri: settings.redirectUrl,
        callback: authCallback
      });
    }
  }, [isLoaded, authCallback]);

  useEffect(() => {
    initialize();
  }, [initialize]);

  const login = SYNOSSO.login;

  const logout = useCallback(() => {
    if (userInfo.isLoggedIn) {
      SYNOSSO.logout(() => {
        saveAccessToken(undefined);
        setUserInfo(defaultUserInfo);
        setIsLoaded(false);
      });
    }
  }, [userInfo, setUserInfo]);

  const value = useMemo<AuthContext>(() => ({ user: userInfo, isLoaded, isConnecting, login, logout }), [
    isLoaded,
    isConnecting,
    userInfo,
    login,
    logout
  ]);

  if (!isLoaded) {
    return <WaitPage />;
  }

  return <Context.Provider value={value} {...props} />;
};
