import { IStyleFunction } from '@fluentui/react/lib/Utilities';
import { IWaitPageStyleProps, IWaitPageStyles } from './WaitPage.types';

export const getStyles: IStyleFunction<IWaitPageStyleProps, IWaitPageStyles> = ({
  className
}): IWaitPageStyles => {
  return {
    root: [
      'login-form',
      {
        display: 'flex',
        flexFlow: 'column nowrap',
        width: 'auto',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center'
      },
      className
    ]
  };
};
