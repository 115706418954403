const settings = {
  synoUrl: 'https://hq.madd0.com',
  appId: 'f99ab78f3603d2a880a3e011e8afdd0c',
  redirectUrl: 'https://localhost:3000',
  proxyUrl: 'https://home2.madd0.com/no.php?csurl=',
  vera: {
    id: '45105070',
    ip: '192.168.0.4',
    userPollDelay: 10000,
    deviceNum: 0
  },
  prod: {
    appId: '7f002afae58ea1f4aa1d067fb7b7f321',
    redirectUrl: 'https://home2.madd0.com',
    vera: {
      userPollDelay: 300000
    }
  }
};

function getEnvironmentSettings(config: typeof settings): Exclude<typeof settings, 'prod'> {
  const isDev = window.location.hostname === 'localhost';

  return new Proxy(config, {
    get(target, name, receiver) {
      const defaultValue = Reflect.get(target, name, receiver);

      if (isDev) {
        return defaultValue;
      }

      let prodValue = Reflect.get(target.prod, name) || defaultValue;

      if (typeof prodValue === 'object') {
        prodValue = { ...defaultValue, prodValue };
      }

      return prodValue;
    }
  });
}

const envSettings = getEnvironmentSettings(settings);

export default envSettings;
