import { FontWeights, initializeIcons, loadTheme, mergeStyles } from '@fluentui/react';
import { FluentTheme } from '@uifabric/fluent-theme/lib/fluent/FluentTheme';
import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './App';
import { Providers } from './Providers';
//import { SunflowerTheme } from './themes/sunflower';

initializeIcons();

const theme = loadTheme(FluentTheme);

// Inject some global styles
mergeStyles({
  selectors: {
    ':global(body), :global(html), :global(#root)': [
      theme.fonts.medium,
      {
        boxSizing: 'border-box',
        margin: 0,
        padding: 0,
        height: '100vh',
        background: theme.semanticColors.bodyBackground,
        color: theme.semanticColors.bodyText
      }
    ],
    ':global(*), :global(*:before), :global(*:after)': {
      boxSizing: 'inherit'
    },
    ':global(h1)': [
      theme.fonts.xxLargePlus,
      {
        fontWeight: FontWeights.semibold,
        marginTop: '0.33em'
      }
    ]
  }
});

ReactDOM.render(
  <Providers>
    <App />
  </Providers>,
  document.getElementById('root')
);
