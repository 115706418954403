import React, { FC, Suspense } from 'react';
import { WaitPage } from './components/WaitPage';
import { useAuth } from './context/AuthProvider';

const LoginForm = React.lazy(() => import('./components/LoginForm'));
const Main = React.lazy(() => import('./components/Main'));

export const App: FC = () => {
  const { user } = useAuth();

  return <Suspense fallback={<WaitPage />}>{user.isLoggedIn ? <Main /> : <LoginForm />}</Suspense>;
};
