import { createContext, useContext } from 'react';
import { AuthContext, UserInfo } from './AuthProvider.types';

export const defaultUserInfo: UserInfo = { isLoggedIn: false };

export const Context = createContext<AuthContext>({
  isLoaded: false,
  isConnecting: false,
  user: defaultUserInfo,
  login: () => null,
  logout: () => null
});

export const useAuth = (): AuthContext => {
  const context = useContext(Context);

  if (context === undefined) {
    throw new Error(`useAuth must be used within a AuthProvider`);
  }

  return context;
};
