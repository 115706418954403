import { classNamesFunction, Spinner, Stack, styled } from '@fluentui/react';
import React, { FC } from 'react';
import { getStyles } from './WaitPage.styles';
import { IWaitPageProps, IWaitPageStyleProps, IWaitPageStyles } from './WaitPage.types';

const getClassNames = classNamesFunction<IWaitPageStyleProps, IWaitPageStyles>();

const _WaitPage: FC<IWaitPageProps> = ({ theme, styles, className }) => {
  const classNames = getClassNames(styles, { theme, className });
  return (
    <Stack className={classNames.root}>
      <Spinner />
    </Stack>
  );
};

export const WaitPage: FC<IWaitPageProps> = styled(_WaitPage, getStyles);
